import React from 'react';
import { graphql } from 'gatsby';
import { Container } from 'theme-ui';
import Layout from '@solid-ui-layout/Layout';
import Divider from '@solid-ui-components/Divider';
import Header from '@solid-ui-blocks/Header/Block01';
import Hero from '@solid-ui-blocks/Hero/Block02';
import Features from '@solid-ui-blocks/Features/Block02';
import Features2 from '@solid-ui-blocks/Features/Block06';
import FeatureTabOne from '@solid-ui-blocks/FeaturesWithPhoto/Block05';
import FeatureTabTwo from '@solid-ui-blocks/FeaturesWithPhoto/Block06';
import Footer from '@solid-ui-blocks/Footer/Block01';
import { normalizeBlockContentNodes } from '@blocks-helpers';
import theme from './_theme';
import styles from './_styles';
import { Helmet } from 'react-helmet';
import ContactModal from '@solid-ui-blocks/ContactModal/Block01';

const JurimetriaPage = props => {
    const { allBlockContent } = props.data;
    const content = normalizeBlockContentNodes(allBlockContent?.nodes);

    return (
        <Layout theme={theme} {...props}>
            <Helmet>
                <title>Use a jurimetria para melhorar sua advocacica</title>
                <meta name="title" content="Use a jurimetria para melhorar sua advocacica | JUIT Rimor" />
                <meta
                    name="description"
                    content="Obtenha mais previsibilidade sobre suas teses. Encontre padrões de julgamento. Entenda como órgãos julgadores apreciam matérias de seu interesse. Comece agora!"
                />

                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://juit.io/jurimetria/index.html" />
                <meta property="og:title" content="Use a jurimetria para melhorar sua advocacica | JUIT Rimor" />
                <meta
                    property="og:description"
                    content="Obtenha mais previsibilidade sobre suas teses. Encontre padrões de julgamento. Entenda como órgãos julgadores apreciam matérias de seu interesse. Comece agora!"
                />
                <meta property="og:image" content="https://storage.googleapis.com/juit-io-logos/JUIT-metabg-home.png" />

                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://juit.io/jurimetria/index.html" />
                <meta property="twitter:title" content="Use a jurimetria para melhorar sua advocacica | JUIT Rimor" />
                <meta
                    property="twitter:description"
                    content="Obtenha mais previsibilidade sobre suas teses. Encontre padrões de julgamento. Entenda como órgãos julgadores apreciam matérias de seu interesse. Comece agora!"
                />
                <meta property="twitter:image" content="https://storage.googleapis.com/juit-io-logos/JUIT-metabg-home.png" />
                <script type='text/javascript'>
                    {`
                    const isBrowser = typeof window !== 'undefined';
                    const _linkedin_partner_id = '4539113';
                    
                    if (isBrowser) {
                        window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
                        window._linkedin_data_partner_ids.push(_linkedin_partner_id);
                        (function (l) {
                            if (!l) {
                                window.lintrk = function (a, b) {
                                    window.lintrk.q.push([a, b]);
                                };
                                window.lintrk.q = [];
                            }
                            var s = document.getElementsByTagName('script')[0];
                            var b = document.createElement('script');
                            b.type = 'text/javascript';
                            b.async = true;
                            b.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js';
                            s.parentNode.insertBefore(b, s);
                        })(window.lintrk);
                    }
                    `}
                </script>
                <noscript>
                    {`<img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=4539113&fmt=gif" />`}
                </noscript>
            </Helmet>
            {/* Modals */}
            <ContactModal />
            {/* Blocks */}
            <Header permanentBlack content={content['header-light']} menuJustify="space-around" />
            <Divider space="5" />
            <Container variant="full" sx={styles.heroContainer}>
                <Hero content={content['hero']} />
            </Container>
            <Divider space="5" />
            <Divider space="5" />
            <Features2 content={content['features2']} />
            <Divider space="5" />
            <Divider space="5" />
            <Container variant="wide" sx={styles.featuresContainer}>
                <FeatureTabOne content={content['feature-tab-one']} reverse />
                <Divider space="2" />
                <FeatureTabTwo content={content['feature-tab-two']} />
            </Container>
            <Divider space="5" />
            <Divider space="5" />
            <Features content={content['features']} />
            <Divider space="5" />
            <Divider space="5" />
            <Footer content={content['footer']} />
        </Layout>
    );
};

export const query = graphql`
    query JurimetriaPageBlockContent {
        allBlockContent(filter: { page: { in: ["site/jurimetria", "shared"] } }) {
            nodes {
                ...BlockContent
            }
        }
    }
`;
export default JurimetriaPage;
